<template>
  <div class="container animationOpacity">
    <div class="row py-5">
      <div class="flex">
        <div class="pointer"></div>
        <p class="title-modal">{{ $t('select_site.title') }}</p>
      </div>
      <ul class="blog-list">
        <li v-for="item in sites" :key="item.id" @click="select(item)">
          {{ item.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'

export default {
  data() {
    return {
      sites: ""
    }
  },
  methods: {
    select(item) {
      this.$store.dispatch("auth/blogSelected", item)
      Cookies.remove("selectSites")
    }
  },
  mounted() {
    var sites = Cookies.get('selectSites')
    this.sites = sites;
  }
}
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
}

.pointer {
  width: 9px;
  height: 9px;
  background: var(--maincolor);
  border-radius: 1px;
  margin-top: 8px;
  margin-right: 15px;
}

.title-modal {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #333333;
}

.blog-list {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    display: block;
    padding: 10px;
    background: #fefefe;
    cursor: pointer;
    border: 1px #ddd solid;
    border-radius: 2px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    &:hover {
      background: var(--maincolortrans);
      border: 1px solid var(--maincolor);
    }
  }
}
</style>
